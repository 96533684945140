<template>
  <div>

    <div class="form">
      <el-form :inline="true" label-width="80px" :model="form" @submit.native.prevent >
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="form.roleName" clearable placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" native-type="submit" @click="onSubmit(form)">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="list" style="min-height: calc(100vh - 214px)">
      <div class="operation">
        <el-button @click="addClick" size="small" type="primary">添加角色</el-button>
      </div>

      <el-table
          :data="list"
          style="width: 100%">
        <el-table-column
            prop="roleName"
            align="center"
            label="角色名称">
          <template slot-scope="scope">
            <span class="blue" @click="deta(scope.row)" >{{ scope.row.roleName }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="roleDesc"
            align="center"
            label="角色描述">
        </el-table-column>
        <el-table-column
            prop="createTime"
            align="center"
            label="创建时间">
        </el-table-column>
        <el-table-column
            prop="authority"
            align="center"
            label="权限"
            show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            fixed="right"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <el-popconfirm
                title="是否要删除此角色？"
                @confirm="del(scope.row)"
            >
              <el-button slot="reference" type="text" size="small" >删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>
export default {
  name: "Role",
  data() {
    return {
      form: {
        roleName: null,
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      list: []
    }
  },
  created() {
    this.getList(this.form);
  },
  mounted() {

  },
  methods: {
    getList(e) {
      this.$get("Role",e)
        .then(res => {
          console.log(res);
          if(res) {
            this.total= res.data.total;
            this.list= res.data.list;
          }
        })
    },
    addClick() {
      this.$router.push({
        path: "/RoleDetail"
      })
    },
    del(e) {
      this.$del("Role",e.id,true)
        .then(res => {
          if(res) {
            this.getList(this.form);
          }
        })
    },
    onSubmit(e) {
      e.pageNum= 1;
      this.getList(this.form);
    },
    handleSizeChange(e) {
      this.form.pageSize= e;
      this.getList(this.form);
    },
    handleCurrentChange(e) {
      this.form.pageNum= e;
      this.getList(this.form);
    },
    deta(e) {
      this.$router.push({
        path: "/RoleDetail",
        query: {
          id: e.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
 @import "../../style/System/Role";
</style>
